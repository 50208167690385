export const registration = `<p>

EARLY REGISTRATION DEADLINE: July 22nd</br>

<strong>Regular £250</strong> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
<strong>Student £200</strong></p>


The conference fee includes proceedings, conference reception and dinner, and coffee breaks. We also offer our guests the possibility of booking accommodation through the University at discounted rates (option available during the early registration process only).</p>

<p>In order to register for the conference, please access our <strong><a rel="stylesheet" href='https://store.lboro.ac.uk/conferences-and-events/computer-science/upcoming-conferencesevents/international-conference-on-words-words-2019'>University Store Webpage</a></strong>.</p>


<p>LATE REGISTRATION (From July 23rd)</br>

<strong>Regular £300</strong> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
<strong>Student £230</strong></p>`;

export const location = `
	<p>Loughborough University, UK.<br>

	In order to plan your travel, please consult the following webpages:</br> 
	-- the <strong><a href='https://www.lboro.ac.uk/about/find-us/'>FIND US</a></strong> University webpage,</br>  
	-- the <strong><a href='https://www.lboro.ac.uk/international/locations/loughborough/travel-information/'>TRAVEL INFORMATION</a></strong> University webpage.</p>

	<h5>Further Information</h5>

	<p>If you are traveling from London Luton airport, there is a direct train to Loughborough. From all other airports in London, you can reach Loughborough from either St. Pancras International by train, or from Victoria Coach Station by coach.</p>

	<p>If you are two or more people traveling from Luton or Stansted, you might want to consider booking an advance taxi (check prices on <strong><a href='https://www.minicabit.com'>minicabit.com</a></strong>) as this option might be cheaper and more convenient than the train.</p>

	<p>From Birmingham Airport, you can either go to the city’s coach station and take a bus to Loughborough for about £10 (see the <strong><a href='https://www.nationalexpress.com/en'>nationalexpress.com</a></strong> website), or book directly a hire taxi for a return trip of roughly £60 (see <strong><a href='https://www.minicabit.com'>minicabit.com</a></strong> website).</p>

	<p>If you are traveling from East Midlands Airport, then you can easily choose to either take a bus or a taxi (for the latter the fare is about £25 one way if you do not book in advance)</p>

	<p>If you are getting here by train, we advise you (should’ve you not already done that), to book a ticket before the day. If you are 100% sure you will catch a certain train, then an Advance ticket is most of the times cheaper. Please be advised that Advance tickets cannot be used on another service than the specified one (and are non-refundable). Another cheaper option is to buy a (return) train ticket for a time different from the rush-hour – off-peak and super off-peak tickets. For a better description of these types of tickets please have a look at the website <strong><a href='https://www.nationalrail.co.uk/times_fares/ticket_types/46548.aspx'>nationalrail.co.uk</a></strong>.</p>

	<p>In case of online/advanced bookings, make sure that you have with you the credit card used to make these bookings, as most of the times you need this in order to collect your train tickets from a ticket machine.</p>

	<p>To get a good feeling of the options you have traveling here (and around UK), have a look at the website <strong><a href='https://traintimes.org.uk'>traintimes.org.uk</a></strong>. To make booking, use either <strong><a href='https://www.eastmidlandsrailway.co.uk'>eastmidlandsrailway.co.uk</a></strong>, <strong><a href='www.nationalrail.co.uk'>nationalrail.co.uk</a></strong> or <strong><a href='https://www.thetrainline.com'>www.thetrainline.com</a></strong>. You can also book some cheap Advance tickets on the <strong><a href='https://uk.megabus.com'>megabus.com website</a></strong>.</p>

	<p>Furthermore, on the uk.megabus.com website as well as the nationalexpress.com website, you can book coach tickets to Loughborough. For the former, the bus actually stops right next to the campus.</p>

	<p>In Loughborough, there is a direct bus line running from the train station, through town, directly to the campus (Sprint Kinchbus <strong><a href='https://www.kinchbus.co.uk'>kinchbus.co.uk</a></strong>). If not, the walk is about 30 mins long, while the taxi ride is roughly £7-8.</p>

	<p>While you are on campus, all of the Kinch busses run free of charge.</p>

	<p>Should you have any questions do not hesitate to contact us.</p>
	`;