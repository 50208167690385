import { Component, OnInit } from '@angular/core';
import { ExternalStringPipe } from 'src/app/pipes/external-string.pipe';
import { AppComponent } from '../../app.component';

@Component({
  selector: '[app-landing-section]',
  templateUrl: './landing-section.component.html',
  styleUrls: ['./landing-section.component.css']
})
export class LandingSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
        
  }

}
